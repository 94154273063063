
export const DURATIONS = [
    {value: 900, name: '15 min'},
    {value: 1800, name: '30 min'},
    {value: 2700, name: '45 min'},
    {value: 3600, name: '1 h'},
    {value: 7200, name: '2 h '}
]

export const RESTS = [
    {value: 0, name: 'Aucune'},
    {value: 300, name: '5 min'},
    {value: 600, name: '10 min'},
    {value: 900, name: '15 min'}
]