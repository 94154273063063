import { render, unmountComponentAtNode } from 'react-dom';
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import DatePicker from "react-datepicker"
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

import Items from './components/items.js'

import 'react-datepicker/dist/react-datepicker.css'


const Products = ({}) => {
    return (
        <div>
            <Items />
        </div>
    )
}

class ProductsElement extends HTMLElement {
   connectedCallback() {
      render(
         <Products />, this
      );
   }

   disconnectedCallback() {
      unmountComponentAtNode(this);
   }
}

customElements.define('products-element', ProductsElement);
