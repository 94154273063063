import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';

import {DURATIONS, RESTS} from '../constants.js'

const DEFAULT_PRODUCT = {name: "", content: "", duration: 0, rest: 0, price: 0}

const Title = ({title, description, setNewMode}) => {
    return (
        <div className={'row'}>
            <div className={'col-lg-6'}>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
            <div className={'col-lg-6 text-right'}>
                <button className={'btn btn-primary'} onClick={() => setNewMode(true)}>Ajouter un service</button>
            </div>
        </div>
    )
}

const Separator = () => {
    return (
        <div className="row">
            <div className="col-lg-9">
                <div className="separator"></div>
            </div>
        </div>
    )
}

const InputCustom = ({label, name, value, handleChange}) => {
    return (
        <div className={'input_custom'}>
            <div className={'input_custom__label'}>
                {label}
            </div>
            <div className={'input_custom__items'}>
                <input className={'form-control'} type="text" name={name} value={value} onChange={(e) => handleChange(e.target.value)} />
            </div>
        </div>
    )
}

const SelectCustom = ({label, value, options, handleChange}) => {
    const getValues = () => {
        return options.map((option, index) => {
            if(option.value == value){
                return (
                    <div key={index} className={'select_custom__item active'} onClick={() => handleChange(option.value)}>{option.name}</div>
                )
            }
            return (
                <div key={index} className={'select_custom__item'} onClick={() => handleChange(option.value)}>{option.name}</div>
            )
        })
    }
    return (
        <div className={'select_custom'}>
            <div className={'select_custom__label'}>
                {label}
            </div>
            <div className={'select_custom__items'}>
                {getValues()}
            </div>
        </div>
    )
}

const formatPrice = (value) => {
    const strPrice = _.toString(value)
    const intPart = strPrice.slice(0, strPrice.length - 2)
    const floatPart = strPrice.slice(-2)
    return intPart + ',' + floatPart + '€' 
}

const getAmount = (value) => {
    return _.round(value, 2)
}
const getFees = (value) => {
    const amount = getAmount(value)
    return _.round(amount * 0.15, 2)
}

const getDuration = (value) => {
    const duration = _.find(DURATIONS, ['value', value])
    return _.get(duration, 'name', '')
}

const convertToPrice = (value) => {
    const number = _.toNumber(value)
    return _.round(number * 100, 0)
}

const fromPrice = (value) => {
    return _.toString(_.round(value / 100, 2))
}

const getWithTax = (value) => {
    return (value + _.round(value * 0.20, 2))
}


const PriceCustom = ({value, handleChange}) => {
    const price = getAmount(value)
    const fees = getFees(value)
    const amount = getWithTax(price + fees)
    return (
        <div className={'pricing'}>
            <label>Tarif hors taxe</label>
            <input name={'price'} className={'form-control'} value={fromPrice(value)} onChange={(event) => handleChange(convertToPrice(event.target.value))} />
            <div className={'line'}>
                <div className={'label'}>Visio de 45 min</div>
                <div className={'value'}>{formatPrice(price)}</div>
            </div>
            <div className={'line'}>
                <div className={'label'}>Comission de 15%</div>
                <div className={'value'}>{formatPrice(fees)}</div>
            </div>
            <div className={'line'}>
                <div className={'label highlight'}>Coût TTC</div>
                <div className={'value'}>{formatPrice(amount)}</div>
            </div>
        </div>
    )
}

const NewProduct = ({newMode, setNewMode, handleProductNew}) => {

    const [payload, setPayload] = useState({...DEFAULT_PRODUCT})

    const handleUpdate = (field, value) => {
        let payloadClone = {...payload}
        payloadClone[field] = value
        setPayload({...payloadClone})
    }

    const cancel = () => {
        setPayload({...DEFAULT_PRODUCT})
        setNewMode(false)
    }

    const submit = () => {
        handleProductNew(payload)
        setNewMode(false)
    }

    if(newMode){
        return (
            <div className={'pro_products_item pro_products_item__update'}>
                <InputCustom name={'name'} label={'Description du créneau'} value={payload.name} handleChange={(value) => handleUpdate('name', value)} />
                <SelectCustom name={'duration'} label={'Définissez la durée de vos visios'} options={DURATIONS} value={payload.duration} handleChange={(value) => handleUpdate('duration', value)} />
                <SelectCustom name={'rest'} label={'Définissez le temps de pause entre chaque visio'} options={RESTS} value={payload.rest} handleChange={(value) => handleUpdate('rest', value)} />
                <PriceCustom value={payload.price} handleChange={(value) => handleUpdate('price', value)} />
                <div className={'actions'}>
                    <button className={'btn btn-default'} onClick={() => submit()}>Ajouter</button>
                    <button className={'btn btn-secondary'} onClick={() => cancel()}>Annuler</button>
                </div>
            </div>
        )
    }

    return null

}


const Product = ({data, handleProductUpdate, handleProductDelete}) => {

    const [editMode, setEditMode] = useState(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const [payload, setPayload] = useState({...data}) 

    const handleUpdate = (field, value) => {
        let payloadClone = {...payload}
        payloadClone[field] = value
        setPayload({...payloadClone})
    }

    const cancel = () => {
        setPayload({...data})
        setEditMode(false)
        setDeleteMode(false)
    }

    const submit = () => {
        handleProductUpdate(payload)
        setEditMode(false)
    }

    const remove = () => {
        handleProductDelete(payload)
    }

    const price = getAmount(payload.price)
    const fees = getFees(payload.price)
    const amount = price + fees

    if(deleteMode) {
        return (
            <div className={'pro_products_item'}>
                <div>Vous souhaitez supprimer le service suivant ?</div>
                <div className={'pro_products_item_name'}>{data.name}</div>
                <div className={'pro_products_item_content'}>{data.content}</div>
                <ul className={'pro_products_item_tags'}>
                    <li>{getDuration(data.duration)}</li>
                    <li>{formatPrice(amount)}</li>
                </ul>
                <div className={'actions'}>
                    <button className={'btn btn-danger'} onClick={() => remove()}>Supprimer</button>
                    <button className={'btn btn-secondary'} onClick={() => cancel()}>Annuler</button>
                </div>
            </div>
        )
    }

    if(editMode) {
        return (
            <div className={'pro_products_item pro_products_item__update'}>
                <InputCustom name={'name'} label={'Titre'} value={payload.name} handleChange={(value) => handleUpdate('name', value)} />
                <InputCustom name={'content'} label={'Description'} value={payload.content} handleChange={(value) => handleUpdate('content', value)} />
                <SelectCustom name={'duration'} label={'Définissez la durée de vos visios'} options={DURATIONS} value={payload.duration} handleChange={(value) => handleUpdate('duration', value)} />
                <SelectCustom name={'rest'} label={'Définissez le temps de pause entre chaque visio'} options={RESTS} value={payload.rest} handleChange={(value) => handleUpdate('rest', value)} />
                <PriceCustom value={payload.price} handleChange={(value) => handleUpdate('price', value)} />
                <div className={'actions'}>
                    <button className={'btn btn-default'} onClick={() => submit()}>Enregistrer</button>
                    <button className={'btn btn-secondary'} onClick={() => cancel()}>Annuler</button>
                    <button className={'btn btn-primary btn-delete'} onClick={() => setDeleteMode(true)}><i className={'icon-icon-trash-bin'}></i></button>
                </div>
            </div>
        )
    }

    return (
        <div className={'pro_products_item'}>
            <div className={'pro_products_item_name'}>{data.name}</div>
            <div className={'pro_products_item_content'}>{data.content}</div>
            <ul className={'pro_products_item_tags'}>
                <li>{getDuration(data.duration)}</li>
                <li>{formatPrice(amount)}</li>
            </ul>
            <button className={'btn btn-secondary btn-update'} onClick={() => setEditMode(true)}>Modifier</button>
            {/*<button className={'btn btn-small btn-secondary'} onClick={() => setDeleteMode(true)}>Supprimer</button>*/}
        </div>
    )

} 

const List = ({products, handleProductUpdate, handleProductDelete}) => {
    return products.map((product) => {
        return (
            <Product 
                key={product.id} 
                data={product} 
                handleProductUpdate={handleProductUpdate} 
                handleProductDelete={handleProductDelete}
            />
        )
    })
}

const Items = () => {

    const [products, setProducts] = useState([])
    const [newMode, setNewMode] = useState(false)
    //const [product, setProduct] = useState({...DEFAULT_PRODUCT})

    useEffect(() => {
        axios.get('/api/pro/products', {}).then((res) => {
            const {data} = res
            setProducts(data)
         }).catch((e) => {
         });
    }, [])

    const handleProductNew = (product) => {
        axios.post('/api/pro/product', product).then((res) => {
            const {data} = res
            const productsClone = [...products]
            productsClone.push(data)
            setProducts(productsClone)
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleProductUpdate = (product) => {
        axios.post('/api/pro/product/' + product.id, product).then((res) => {
            const {data} = res
            const productsClone = [...products]
            const findIndex = _.findIndex(productsClone, ['id', product.id])
            if(findIndex >= 0){
                productsClone[findIndex] = product
                setProducts(productsClone)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleProductDelete = (product) => {
        axios.get('/api/pro/product/' + product.id + '/delete', product).then((res) => {
            const {data} = res
            const productsClone = [...products]
            const findIndex = _.findIndex(productsClone, ['id', product.id])
            if(findIndex >= 0){
                _.pullAt(productsClone, [findIndex])
                setProducts(productsClone)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    const defaultProps = {
        newMode,
        setNewMode,
        products,
        handleProductNew,
        handleProductUpdate,
        handleProductDelete
    }

    return (
        <div className={''}>
            <Title title={'Services'} description={'Définissez vos créneaux de visio.'} {...defaultProps} />
            <NewProduct {...defaultProps} />
            <List {...defaultProps} />
        </div>
    )

}

export default Items